import { Link } from "react-router-dom"
import styles from "./Lessons.module.scss"
import { iconLesson } from "./utils"
import { useLocation } from "react-router"
import React from "react"
import Status from "../Status/Status"
import Typography from "../UIKit/base/Typography"
import classNames from "classnames"
import PropTypes from "prop-types"
import { LessonItemType } from "../../propTypes"
import { LockIcon } from "../icons"
import { productSelector } from "../../store/reducers/products"
import { useSelector } from "react-redux"

function LessonItem({ lesson, state }) {
	const { pathname } = useLocation()

	const isBlocked = lesson.consistent_blocked ?? false

	const course = useSelector(productSelector)
	const isBought = course?.product?.bought
	const isAvailable = course?.product?.available
	const startSoon = !!course?.product?.start_days_left

	const isMobile = window.innerWidth <= 768

	return (
		<Link
			key={`${lesson?.id}-${lesson.type}`}
			to={
				isBlocked || !isBought
					? `/`
					: `${pathname}/${lesson?.type}/${lesson?.id}`
			}
 
			className={classNames(
        styles.link,
				{ [styles.lessonBlocked]: isBlocked },
				{ [styles.notBought]: !isBought || !isAvailable }
			)}
			state={state}
		>
			<div className={styles.lessonWrap}>
				<div className={styles.arrStructure_wrap_right}>
					<div className={styles.iconLesson}>
						{isBlocked || (isBought && startSoon) ? (
							<LockIcon />
						) : (
							iconLesson(lesson?.type)
						)}
					</div>
					<div>
						<Typography
							fontVariant="medium"
							className={classNames(styles.moduleTitle, {
								[styles.no_active]:
									lesson?.status === "not_started" ||
									isBlocked ||
									(isBought && startSoon),
							})}
						>
							{lesson?.title}
						</Typography>
					</div>
				</div>
				<div className={styles.arrStructure_wrap_right}>
					{isBought && (
						<Status className={styles.status} isMobile={isMobile}>
							{lesson.status}
						</Status>
					)}
				</div>
			</div>
		</Link>
	)
}

export default function Lessons({ lessons, state }) {
	if (!lessons?.length) {
		return null
	}

	return (
		<div className={styles.lwssonWrap}>
			{lessons.map((e) => (
				<LessonItem key={`${e.id}-${e.type}`} lesson={e} state={state} />
			))}
		</div>
	)
}

LessonItem.propTypes = {
	lesson: PropTypes.shape(LessonItemType),
}

Lessons.propTypes = {
	lessons: PropTypes.arrayOf(PropTypes.shape(LessonItemType)),
}
