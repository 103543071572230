import React, { useRef, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  attachmentsSelector,
  lessonMoveStatuses,
  postSelector,
  sendLessonStatus,
} from "../../store/reducers/lesson";
import Comments from "../Comments/Comments";
import EditorView from "../UIKit/editor/view/EditorView";
import { createTabs } from "../UIKit/tabs/tabs";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import Attachments from "../attachments/Attachments"; 
import styles from "./lesson.module.scss"; 

export default function Lesson() {
  const post = useSelector(postSelector);
  const attachments = useSelector(attachmentsSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tab, setTab] = useState("SET_MATERIALS"); //TODO:
  const [flag, setFlag] = useState("materials"); //TODO:

  const lessonRef = useRef();

  const isDesc = useMemo(() => {
    //const regex = /(<([^>]+)>)/gi;
    const regex = /<(?!\/?(iframe|img)\b)[^>]+>/gi;
    const descWithoutTags = post?.description && post?.description.replace(regex, "");
    return !!descWithoutTags
      
  }, [post?.description])
 

  const onScroll = () => {
    if (lessonRef.current) {
      const bottom = lessonRef.current?.getBoundingClientRect()?.bottom;

      const scrolledOtEnd = bottom <= window.innerHeight;
      const longText =
        post?.type === "lesson" &&
        !post?.media &&
        lessonRef.current?.clientHeight &&
        lessonRef.current?.clientHeight >= window.innerHeight;

      if (scrolledOtEnd && longText &&
        post?.status !== lessonMoveStatuses.SEND &&
        post?.status !== lessonMoveStatuses.COMPLETED) {
        window.removeEventListener("scroll", onScroll);
        dispatch(sendLessonStatus(lessonMoveStatuses.COMPLETED));
      }
    }
  };

  useEffect(() => {
    const shortText =
      post?.type === "lesson" &&
      !post?.media &&
      post &&
      post?.status !== lessonMoveStatuses.SEND &&
      post?.status !== lessonMoveStatuses.COMPLETED &&
      ((lessonRef.current?.clientHeight && lessonRef.current?.clientHeight < window.innerHeight) || !isDesc)

    if (shortText) {
      dispatch(sendLessonStatus(lessonMoveStatuses.COMPLETED))
    }
  }, [lessonRef.current?.clientHeight, post?.media, dispatch, post, isDesc]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  function handleProgress(e) {
    if (post.status === lessonMoveStatuses.COMPLETED) {
      return;
    }
    const percent = Math.abs((e.target.currentTime / e.target.duration) * 100);
    if (
      percent >= 1 &&
      percent < 80 &&
      post.status !== lessonMoveStatuses.SEND &&
      post.status !== lessonMoveStatuses.IN_PROGRESS
    ) {
      dispatch(sendLessonStatus(lessonMoveStatuses.IN_PROGRESS));
    }
    if (
      percent >= 80 &&
      post.status !== lessonMoveStatuses.SEND &&
      post.status !== lessonMoveStatuses.COMPLETED
    ) {
      dispatch(sendLessonStatus(lessonMoveStatuses.COMPLETED));
    }
  }

  function viewTabs() {
    return (
      <div className={styles.tabLines}>
        {createTabs({
          text: t("additional_materials"),
          url: ``,
          function: handlerMaterials,
          style: {
            color: tab === "SET_MATERIALS" ? "active" : "secondaryDarkGray",
          },
        })}

        {createTabs({
          text: t("comments"),
          url: ``,
          function: handlerComments,
          style: {
            color: tab === "SET_COMMENTS" ? "active" : "secondaryDarkGray",
          },
        })}
        {createTabs({
          text: t("speakers"),
          url: ``,
          function: handlerSpeakers,
          style: {
            color: tab === "SET_SPEAKERS" ? "active" : "secondaryDarkGray",
          },
        })}
      </div>
    );
  }

  function handlerMaterials() {
    setFlag("materials");
    setTab("SET_MATERIALS");
  }

  function handlerComments() {
    setFlag("comments");
    setTab("SET_COMMENTS");
  }

  function handlerSpeakers() {
    setFlag("speakers");
    setTab("SET_SPEAKERS");
  }

  return (
    <>
      {(!!post?.media || isDesc) && 
      <div ref={lessonRef} className={styles.card}>
        <div>
        {post?.media && (
          <div className={styles.video}>     
            <VideoPlayer
              handleProgress={handleProgress}
              url={post?.media?.url}
            />
            </div>
          )}
          {/* <Typography className={classNames(styles.description, styles.no_border)} fontVariant="medium" component="p">{post !== null ?  : null}</Typography> */}
          {isDesc && (
            <EditorView content={post?.description} fontVariant="medium" />
          )}
        </div>
      </div>
      }

      <div className={styles.baseTabLine}>{viewTabs()}</div>
      {tab === "SET_MATERIALS" && (
        <div className={styles.wrapFiles}>
          <Attachments attachments={attachments} />
        </div>
      )}
      {tab === "SET_COMMENTS" && <Comments />}
      {tab === "SET_SPEAKERS" ? <div className={styles.tab}></div> : null}
    </>
  );
}
