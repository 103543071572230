import PropTypes, {
  shape,
  arrayOf,
  array,
  string,
  number,
  bool,
  node,
} from "prop-types";

export const PostType = shape({
  consistent_blocked: bool,
  description: string,
  duration: number,
  id: number,
  speakers: array, ///?
  title: string,
  status: string,
  type: string,
});

export const UserType = shape({});

export const AttachmentType = shape({
  filename: string,
  id: number,
  mime: string,
  size: number,
  sort_order: number,
  url: string,
});

export const AvatarType = shape({
  big: string,
  origin: string,
  small: string,
});

export const PersonType = shape({
  answer: string,
  answered_at: string,
  attachments: arrayOf(shape(AttachmentType)),
  avatar: shape(AvatarType),
  name: string,
});

export const SpeakerType = shape({});

export const AnswerType = shape({
  id: number,
  passing_score: number,
  passing_total: number,
  person: shape(PersonType),
  score: number,
  status: string,
  user: shape(PersonType),
});

export const ModuleType = shape({
  id: number,
  posts: arrayOf(shape(PostType)),
  posts_count: number,
  posts_done: number,
  status: string,
  title: string,
});

export const ModuleInLessonType = shape({
  item: shape(ModuleType),
  postId: string,
  children: node
});

export const CourseType = shape({});

export const StatusType = shape({
  children: node,
  fontVariant: string,
  className: string,
});

export const ScoreProgressType = shape({
  total: number,
  score: number,
});

//lesson
export const LessonItemType = {
  consistent_blocked: PropTypes.bool,
  description: PropTypes.string,
  duration: PropTypes.number,
  id: PropTypes.number,
  speakers: PropTypes.array,
  title: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
};
