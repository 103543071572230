import i18next from "i18next"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
	resetBreadcrumbs,
	setBreadcrumbs,
} from "../../store/reducers/breadcrumbs"
import {
	companyStructureSelector,
	getCompanyStructure,
} from "../../store/reducers/knowledgeBase"
import MobileHeader from "../Mobile/MobileHeader"
import AvatarView from "../UIKit/avatarView/AvatarView"
import Typography from "../UIKit/base/Typography"
import { EyeCloseIcon, EyeIcon } from "../icons"
import JustifyContainer from "../layouts/JustifyContainer"
import { formatEndings } from "../utils"
import NoItems from "./NoItems"
import styles from "./index.module.sass"
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader"
import PreloaderStructure from "../Preloader/Preloaders/PreloaderStructure"
import { MenuMobile } from "../layouts/Header/Header"

export default function CompanyStructure() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const departments = useSelector(
		companyStructureSelector
	)?.company_structure_tree

	const refContainer = useRef(null)

	const [refVisible, setRefVisible] = useState(true)
	const [innerRef, setInnerRef] = useState(0)

	const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			//dispatch(setShowLoader(true))
		}
	}, [dispatch])

	useEffect(() => {
		if (refContainer.current) {
			refContainer.current.scrollLeft +=
				innerRef / 2 - refContainer.current.offsetWidth / 2
		}
	}, [refVisible, innerRef])

	const crumbs = useMemo(() => {
		const arr = [
			{ path: `/knowledge-base`, title: t("knowledge_base") },
			{
				path: `/company-structure`,
				title: t("company_structure"),
			},
		]

		return arr
	}, [t])

	useEffect(() => {
		!loading && dispatch(setBreadcrumbs(crumbs))
		return () => {
			dispatch(resetBreadcrumbs())
		}
	}, [crumbs, dispatch, loading])

	if (loading) {
		return <PreloaderStructure />
	}
	return (
		<>
			<MenuMobile />
			<div className={styles.structure__mobileHeader}>
				<MobileHeader
					title={t("company_structure")}
					breadcrumbsTitle={t("knowledge_base")}
				/>
			</div>
			<JustifyContainer className={styles.structure__header}>
				<Typography
					className={styles.structure__title}
					fontVariant="bold"
					component="h3"
				>
					{t("organization_structure_of_company")}
				</Typography>
			</JustifyContainer>
			{!!departments?.length && (
				<div
					ref={(ref) => {
						refContainer.current = ref
						setRefVisible(!!ref)
					}}
					className={styles.container}
				>
					<div className={styles.tree}>
						<ul className={styles.first_node}>
							{recursion(departments, setInnerRef)}
						</ul>
					</div>
				</div>
			)}

			{!departments?.length && <NoItems />}
		</>
	)
}

const Card = ({ children, department, dept, setInnerRef }) => {
	const [toggle, setToggle] = useState(dept <= 0)

	const {
		count_people,
		manager_avatar,
		manager_name,
		name,
		manager_position_name,
		id,
	} = department

	const ref = useRef(null)

	useEffect(() => {
		if (ref.current) {
			setInnerRef(ref.current.offsetWidth)
		}
	}, [ref.current])
	//useMemo !

	return (
		<li ref={dept === 0 ? ref : null}>
			<Link
				to={`/knowledge-base/company-structure/${id}`}
				className={styles.card}
			>
				<div className={styles.department}>
					<div className={styles.department__name}>
						<Typography>{name}</Typography>
					</div>
					{manager_name && (
						<div className={styles.department__manager}>
							<div className={styles.manager__avatar}>
								<div
									className={`${styles.tabLine_header_name} ${styles.tabLine_name}`}
								>
									<div className={styles.tabLine_name_round}>
										<AvatarView
											name={manager_name}
											src={manager_avatar?.small}
										/>
									</div>
								</div>
							</div>
							<div className={styles.manager__data}>
								<Typography className={styles.manager__name}>
									{manager_name}
								</Typography>
								<Typography className={styles.manager__position}>
									{manager_position_name}
								</Typography>
							</div>
						</div>
					)}
					{!!count_people && (
						<div className={styles.department__people}>
							<Typography>
								{formatEndings(count_people, "people", i18next.language)}
							</Typography>
						</div>
					)}
				</div>
				{children && (
					<Eye
						toggle={toggle}
						onClick={(e) => {
							e.preventDefault()
							setToggle((s) => !s)
						}}
					/>
				)}
			</Link>
			{toggle && children}
		</li>
	)
}

function recursion(departments, setInnerRef, dept = 0) {
	if (!departments?.length) {
		return null
	}
	return departments?.map((d, i) => {
		return (
			<Card key={d?.id} department={d} dept={dept} setInnerRef={setInnerRef}>
				{d?.children?.length && (
					<ul>{recursion(d?.children, setInnerRef, dept + 1)}</ul>
				)}
			</Card>
		)
	})
}

function Eye({ onClick, toggle }) {
	return (
		<button onClick={onClick} className={styles.eye}>
			{toggle ? <EyeCloseIcon /> : <EyeIcon />}
		</button>
	)
}
