import styles from "./products.module.scss";

import classNames from "classnames";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ViewCourseImg } from "../Product";
import Status from "../Status/Status";
import Typography from "../UIKit/base/Typography";
import { BookIcon, LessonIcon, ModuleIcon } from "../icons";
import { formatEndings } from "../utils"; 

const ProductList = ({ products, type }) => {
  const { t } = useTranslation();

  if (!products?.length) {
    return null;
  }

  return products.map((e,i) => {
    const status = e?.start_days_left ? e?.start_days_left : e.status;

    return (
      <Link key={`${e.id}-product-${i}`} className={styles.course} to={`/products/${e.id}${e?.path_id ? "?filter[path_id]=" + e.path_id : "" }`}>
        <div
          className={classNames(styles.addCourses_content_img, {
            [styles.no_bg]: !!e?.cover?.big,
          })}
        >
          <ViewCourseImg src={e?.cover?.big} />
          {type === "products" && (
            <Status className={styles.status}>{e.status}</Status>
          )}
          {type === "catalog" && e.bought && e?.available && !e?.start_days_left && (
            <Status className={styles.status}>{"product_bought"}</Status>
          )}
          {e.bought && !!e.start_days_left && (
            <Typography className={styles.status__daysLeft} fontVariant="medium">
              {formatEndings(e?.start_days_left, "day", i18next.language)}{" "}
              {t("till_start")}
            </Typography>
          )}
        </div>
        <div className={styles.addCourses_content_line}>
          <Typography
            fontVariant="medium"
            component="div"
            className={styles.addCourses_content_line_type}
          >
            {t(e.type)}
          </Typography>
        </div>
        <div className={styles.addCourses_content_line}>
          <Typography
            fontVariant="medium"
            component="div"
            className={styles.addCourses_content_line_title}
          >
            {e.name !== "" ? e.name : "-"}
          </Typography>
        </div>
         
          <Typography
            fontVariant="medium"
            component="div"
            className={styles.addCourses_content_line}
          >
            <Typography
              fontVariant=""
              className={styles.addCourses_content_line_text}
            >
             {e.type === "course" &&  (e.speakers ? e.speakers : "-"   )}
             {e.type === "path" && "-" }
            </Typography>
          </Typography>
 
        <div className={styles.addCourses_content_line}>
          {e.type === "course" && (
            <Typography
              fontVariant="medium"
              component="div"
              className={styles.addCourses_content_line_modules}
            >
              <ModuleIcon />
              <Typography fontVariant="medium" component="span">
                {formatEndings(e.modules_count, "module", i18next.language)}
              </Typography>
              <LessonIcon />
              {formatEndings(e.posts_count, "lesson", i18next.language)}
            </Typography>
          )}
          {e.type === "path" && (
            <Typography
              fontVariant="medium"
              component="div"
              className={styles.addCourses_content_line_modules}
            >
              <BookIcon />
              <Typography fontVariant="medium" component="span">
                {formatEndings(e.courses_count, "course", i18next.language)}
              </Typography>
            </Typography>
          )}
        </div>
      </Link>
    );
  });
};

export default ProductList;
