import { Suspense, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Color from "color"
import BasicRouter from "./components/routes/index"
import Alert from "./components/UIKit/alert"
import Preloader from "./components/Preloader/Prepoader"
import {
	getCountries,
	getDomainTheme,
	getLogo,
	getTimezones,
	themeSelector,
} from "./store/reducers/domainTheme"
import Head from "./components/Head"

import AiWidget from "./components/AiWidget/AiWidget"

const PRIMARY_DEFAULT = "#6B4DFF"
const BLACK_DEFAULT = "#15121F"
const WHITE_DEFAULT = "#FBFAFF" 

const App = () => {
	const dispatch = useDispatch()
	const theme = useSelector(themeSelector)

	useEffect(() => {
		dispatch(getDomainTheme())
		dispatch(getTimezones())
		dispatch(getCountries())
		dispatch(getLogo())
	}, [dispatch])

	useEffect(() => {
		if (theme) {
			const  primary_color = theme?.primary_color || PRIMARY_DEFAULT
			const bg_color = theme?.bg_color ? 
				(theme.negate ? Color(theme.bg_color).negate().hex() : theme.bg_color) 
				: WHITE_DEFAULT
			const text_color = theme?.text_color ? 
				(theme.negate? Color(theme.text_color).negate().hex() : theme.text_color)
				: BLACK_DEFAULT

			const pureBlack = theme.negate ? "#ffffff" : "#000000"
			const pureWhite = theme.negate ? "#000000" : "#ffffff"

			const secondaryDarkGray = Color(pureWhite)
				.mix(Color(bg_color))
				.mix(Color(text_color), 0.72)
				.hex()
			const secondaryGray = Color(pureWhite)
				.mix(Color(bg_color))
				.mix(Color(text_color), 0.4)
				.hex()
			const secondaryLightPurple = Color(pureWhite)
				.mix(Color(bg_color))
				.mix(Color(primary_color), 0.16)
				.hex()
			const secondaryBackground = Color(pureWhite)
				.mix(Color(bg_color))
				.mix(Color(primary_color), 0.04)
				.hex()
			const primaryHoverColor = Color(pureWhite)
				.mix(Color(bg_color), 0.24)
				.mix(Color(primary_color))
				.hex()
			const primaryActiveColor = Color(pureBlack)
				.mix(Color(text_color), 0.24)
				.mix(Color(primary_color))
				.hex()

			const shadowColor = Color(primary_color).alpha(0.04).hexa()
			const strongShadowColor = Color(primary_color).alpha(0.12).hexa()
			const primary_color40percent = Color(primary_color).alpha(0.4).hexa()

			const { body } = document
			body.style = `
			--primary_color: ${primary_color};
			--bg_color: ${bg_color};
			--text_color: ${text_color};
			--secondaryDarkGray: ${secondaryDarkGray};
			--secondaryGray: ${secondaryGray};
			--secondaryLightPurple: ${secondaryLightPurple};
			--secondaryBackground: ${secondaryBackground};
			--primaryHoverColor: ${primaryHoverColor};
			--primaryActiveColor: ${primaryActiveColor};
			--shadowColor: ${shadowColor};
			--strongShadowColor: ${strongShadowColor};
			--primary_color40percent: ${primary_color40percent};
		`
		}
	}, [theme])

	return (
		<>
			<Suspense fallback={<Preloader />}>
				<Head />
				<Alert />
				<Preloader />
				<BasicRouter />
				<AiWidget />
			</Suspense>
		</>
	)
}

export default App
