import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  companyStructureSelector,
  getDocuments,
  documentsSelector,
  setSearchedDocuments,
  getCompanyStructure,
  projectIdSelector,
  getFileLink
} from "../../store/reducers/knowledgeBase";
import styles from "./index.module.sass";
import {
  HierarchyIcon,
  DocumentIcon,
  FolderIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "../icons";
import Typography from "../UIKit/base/Typography";
import { useTranslation } from "react-i18next";

import { formatEndings } from "../utils";
import i18next from "i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";
import CompanyStructure from "./CompanyStructure";
import { MenuMobile } from "../layouts/Header/Header";
import MobileTitle from "../Mobile/MobileTitle";
import SearchAi from "./SearchAi/SearchAi";
import CustomizedTooltips from "../UIKit/tooltip/Tooltip";
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader";
import PreloaderKnowbase from "../Preloader/Preloaders/PreloaderKnowbase";
import { dispatch } from "../..";
import { downloadFile } from "../downloadLink";
import PreloaderMini from "../Preloader/PreloaderMini";

export default function KnowlegdeBase() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const departmentsCount = useSelector(
    companyStructureSelector
  )?.project_department_count;

  const documents = useSelector(documentsSelector);

  const loading = useSelector(globalLoadSelector).loading;

  useEffect(() => {
    dispatch(setShowLoader(false));

    return () => {
      dispatch(setShowLoader(true));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDocuments());
    dispatch(getCompanyStructure());

    return () => {
      dispatch(setSearchedDocuments(null));
    };
  }, [dispatch]);

  let documentsExist = true;
  documentsExist = documents?.some((docFolder) => docFolder.existed === true);

  if (loading) {
    return <PreloaderKnowbase />;
  }

  if (!loading && !documentsExist) {
    return (
      <div className={styles.knowbase}>
        <div className={styles.structureInKnowbase}>
          <CompanyStructure />
        </div>
      </div>
    );
  }

	return (
		<>
			<MobileTitle title={t("knowledge_base")} showBackArrow={false} />
			<MenuMobile />
			<div className={styles.knowbase}>
				<div className={styles.knowbase__header}>
					{documentsExist && (
						<Typography
							className={styles.knowbase__title}
							fontVariant="bold"
							component="h3"
						>
							{t("knowledge_base")}
						</Typography>
					)}
					{/*documentsExist && (
            <div className={styles.knowbase__search}>
              <SearchAi className={styles.search_base} />
            </div>
          )*/}
				</div>

				{documentsExist && (
					  <>
                {departmentsCount && (
                  <div className={styles.knowbase__structure}>
                    <div>
                      <HierarchyIcon />
                      <Typography component={Link} to={"./company-structure"}>
                        {t("organization_structure")}
                      </Typography>
                    </div>
                    <Typography component="span">
                      {formatEndings(
                        departmentsCount,
                        "department",
                        i18next.language
                      )}
                    </Typography>
                  </div>
                )}

                {documents?.length && (
                  <div className={styles.documents__wrapper}>
                  <div
                    className={classNames(
                      styles.knowbase__documents,
                      styles.documents
                    )}
                  >
                    <div
                      className={classNames(
                        styles.documents__row,
                        styles.documents__head
                      )}
                    >
                      <div className={styles.documents__left}>
                        <Typography>{t("documents_name")}</Typography>
                      </div>
                      <div className={styles.documents__center}>
                        <Typography>{t("type")}</Typography>
                      </div>
                      <div className={styles.documents__right}>
                        <Typography>{t("number_of_documents")}</Typography>
                      </div>
                    </div>

                    {!!documents?.length &&
                      recursion(documents, 0, false, null)}
                  </div>
                  </div>
                )}


        </>
        )}
        </div>
    </>
  );
}

export const DocumentsFolderList = ({
  docFolder,
  isSidebar,
  currDocId,
  children,
  gap,
}) => {
  const [showFiles, setShowFiles] = useState(true);

  const isRoot = docFolder?.is_root;
  const {t} = useTranslation()
  const projectId = useSelector(projectIdSelector)

  const handleClick = () => {
    setShowFiles((prev) => !prev);
  };

  const documentsExist =
    docFolder?.kb_documents_list?.some((doc) => doc.existed === true) ||
    !!docFolder?.children?.length;

  return (
    <>
      {!!documentsExist && (
        <>
          <div
            key={docFolder.id}
            className={classNames(styles.documents__row, {
              [styles.document__sidebar]: isSidebar,
              [styles.document__rootFolder]: isRoot,
            })}
          >
            <div
              className={styles.documents__left}
              style={{ paddingLeft: gap + "px" }}
            >
              <div className={styles.documents__folder} onClick={handleClick}>
                <div className={styles.documents__arrow}>
                  {showFiles ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </div>
                <FolderIcon />
                <CustomizedTooltips
                  arrow
                  title={
                    <Typography
                      component="p"
                      className={styles.folder__name_tooltip}
                    >
                      <Typography component="span">{docFolder.name}</Typography>
                    </Typography>
                  }
                >
                  <Typography className={styles.folder__name}>
                    {docFolder.name}
                  </Typography>
                </CustomizedTooltips>
              </div>
            </div>
            <div className={styles.documents__center}>
                          <Typography>{t("folder")}</Typography>
                        </div>
            <div className={styles.documents__right}>
              {!!docFolder?.kb_documents_list.length && (
                <Typography>
                  {formatEndings(
                    docFolder.kb_documents_list.length,
                    "document",
                    i18next.language
                  )}
                </Typography>
              )}
            </div>
          </div>

          {showFiles && (
            <>
              {children}
              {!!docFolder?.kb_documents_list.length &&
                docFolder?.kb_documents_list.map((doc) => <DocumentFile doc={doc} currDocId={currDocId} gap={gap} isSidebar={isSidebar} isRoot={isRoot} />)}
            </>
          )}
        </>
      )}
    </>
  );
};

export function recursion(
  documents,
  gap = 0,
  isSidebar = false,
  currDocId = null
) {
  if (!documents?.length) {
    return null;
  }

	return documents.map((docFolder, i) => {
		return(
		<DocumentsFolderList
			key={docFolder.id + "-" + i}
			docFolder={docFolder}
			gap={gap}
			isSidebar={isSidebar}
			currDocId={currDocId}
		>
			{!!docFolder?.children?.length &&
				recursion(
					docFolder?.children,
					gap + (isSidebar ? 44 : 64),
					isSidebar,
					currDocId
				)}
		</DocumentsFolderList>
	)})
}


const DocumentFile = ({doc, gap, isSidebar, currDocId, isRoot}) => {
  

    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()

    const handleDownload = (documentId, filename) => {
      setLoading(true)
      dispatch(getFileLink({documentId, filename, cb: () => setLoading(false)}))
    }
  

    return (
    <React.Fragment key={doc.document_id}>
      {doc.existed && (
        <div
          className={classNames(styles.documents__row, {
            [styles.document__sidebar]: isSidebar,
          })}
        >
          <div className={styles.documents__left}>
            <div
              className={classNames(styles.documents__file, {
                [styles.document__isCurrent]:
                  +currDocId === doc.document_id,
                [styles.document__rootFile]: isRoot,
              })}
              style={{ marginLeft: gap + "px" }}
            >
              {!loading ? <DocumentIcon /> : <PreloaderMini />}
              {doc.existed && (
                <CustomizedTooltips
                  arrow
                  title={
                    <Typography
                      component="p"
                      className={styles.folder__name_tooltip}
                    >
                      <Typography component="span">
                        {doc.name}
                      </Typography>
                    </Typography>
                  }
                >
                  {!!doc?.type ? 
                    <Typography  
                    className={classNames(
                      styles.document__link,
                      styles.folder__name,
                      styles.document__file
                    )}
                    onClick={() => handleDownload(doc?.id, doc.name)}
                  >
                    {doc.name}
                  </Typography>
                  : 
                  <Typography
                  component={Link}
                  to={`/knowledge-base/documents/${doc.document_id}`}
                  className={classNames(
                    styles.document__link,
                    styles.folder__name
                  )}
                >
                  {doc.name}
                </Typography>
                }
                   
                </CustomizedTooltips>
              )}

              {!doc.existed && (
                <CustomizedTooltips
                  arrow
                  title={
                    <Typography
                      component="p"
                      className={styles.folder__name_tooltip}
                    >
                      <Typography component="span">
                        {doc.name}
                      </Typography>
                    </Typography>
                  }
                >
                  <Typography component="span">
                    {doc.name}
                  </Typography>
                </CustomizedTooltips>
              )}
            </div>
          </div>
          <div className={styles.documents__center}>
            <Typography>{t(doc.type) || t("document")}</Typography>
          </div>
          <div className={styles.documents__right}>
            <Typography>-</Typography>
          </div>
        </div>
      )}
    </React.Fragment>)

}
