import Color from "color";

export const breakpoints = {
    maxMobile: 768,
    minTable: 769,
    maxTable: 1200
}

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatCountRu(count, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return `${count} ${
        titles[
            count % 100 > 4 && count % 100 < 20
                ? 2
                : cases[count % 10 < 5 ? count % 10 : 5]
        ]
    }`;
}

export function getMinutes(value, lang = "en") {
    if (!value) {
        return "-";
    }

    if (value < 60 && lang === "en") {
        return value === 1 ? `${value} s` : `${value} s`;
    }

    if (value >= 60 && lang === "en") {
        return Math.round(value / 60) + (value < 120 ? " m" : " m");
    }

    if (value < 60 && lang === "ru") {
        return formatCountRu(value, ["сек", "сек", "сек"]);
    }

    if (value >= 60 && lang === "ru") {
        return formatCountRu(Math.round(value / 60), [
            "мин",
            "мин",
            "мин",
        ]);
    }

    if (value < 60 && lang === "uk") {
        return formatCountRu(value, ["сек", "сек", "сек"]);
    }

    if (value >= 60 && lang === "uk") {
        return formatCountRu(Math.round(value / 60), [
            "хв",
            "хв",
            "хв",
        ]);
    }

    
    if (value < 60 && lang === "it") {
        return formatCountRu(value, ["s", "s", "s"]);
    }

    if (value >= 60 && lang === "it") {
        return formatCountRu(Math.round(value / 60), [
            "m",
            "m",
            "m",
        ]);
    }
} 

export function formatEndings(value, title = "", lang = "en") {
    if (lang === "en") {
        switch (title) {
            case "reply":
                return formatCountRu(value, ["reply", "replies", "replies"])
            case "people":
                return `${value} people`
            default:
                return value === 1 ? `${value} ${title}` : `${value} ${title}s`;
        } 
    }

    if (lang === "ru") {
        switch (title) {
            case "module":
                return formatCountRu(value, ["модуль", "модуля", "модулей"]);
            case "lesson":
                return formatCountRu(value, ["урок", "урока", "уроков"]);
            case "question":
                return formatCountRu(value, ["вопрос", "вопроса", "вопросов"]);
            case "post":
                return formatCountRu(value, ["пост", "поста", "постов"]);
            case "year":
                return formatCountRu(value, ["год", "года", "лет"]);
            case "month":
                return formatCountRu(value, ["месяц", "месяца", "месяцев"]);
            case "day":
                return formatCountRu(value, ["день", "дня", "дней"]);
            case "hour":
                return formatCountRu(value, ["час", "часа", "часов"]);
            case "minute":
                return formatCountRu(value, ["минута", "минуты", "минут"]);
            case "second":
                return formatCountRu(value, ["секунда", "секунди", "секунд"]);
            case "review":
                return formatCountRu(value, ["отзыв", "отзыва", "отзывов"]);
            case "reply":
                return formatCountRu(value, ["ответ", "ответа", "ответов"])
            case "people":
                return formatCountRu(value, ["человек", "человек", "человек"])
            case "department":
                return formatCountRu(value, ["отдел", "отдела", "отделов"])
            case "document":
                return formatCountRu(value, ["документ", "документа", "документов"])
            case 'course':
                return formatCountRu(value, ["курс", "курса", "курсов"])
            case 'view':
                return formatCountRu(value, ["просмотр", "просмотра", "просмотров"])
            default:
                return
        }
    }

    if (lang === "es") {
        switch (title) {
    case "module":
    return formatCountRu(value, ["módulo", "módulos", "módulos"]);
case "lesson":
    return formatCountRu(value, ["lección", "lecciones", "lecciones"]);
case "question":
    return formatCountRu(value, ["pregunta", "preguntas", "preguntas"]);
case "post":
    return formatCountRu(value, ["publicación", "publicaciones", "publicaciones"]);
case "year":
    return formatCountRu(value, ["año", "años", "años"]);
case "month":
    return formatCountRu(value, ["mes", "meses", "meses"]);
case "day":
    return formatCountRu(value, ["día", "días", "días"]);
case "hour":
    return formatCountRu(value, ["hora", "horas", "horas"]);
case "minute":
    return formatCountRu(value, ["minuto", "minutos", "minutos"]);
case "second":
    return formatCountRu(value, ["segundo", "segundos", "segundos"]);
case "review":
    return formatCountRu(value, ["reseña", "reseñas", "reseñas"]);
case "answer":
    return formatCountRu(value, ["respuesta", "respuestas", "respuestas"]);
case "person":
    return formatCountRu(value, ["persona", "personas", "personas"]);
case "department":
    return formatCountRu(value, ["departamento", "departamentos", "departamentos"]);
case "document":
    return formatCountRu(value, ["documento", "documentos", "documentos"]);
case "course":
    return formatCountRu(value, ["curso", "cursos", "cursos"]);
case "view":
    return formatCountRu(value, ["visualización", "visualizaciones", "visualizaciones"]);
    default:
        return
}
}
    if (lang === "uk") {
        switch (title) {
            case "module":
                return formatCountRu(value, ["модуль", "модулі", "модулів"]);
            case "lesson":
                return formatCountRu(value, ["урок", "уроки", "уроків"]);
            case "question":
                return formatCountRu(value, ["питання", "питання", "питань"]);
            case "post":
                return formatCountRu(value, ["пост", "пости", "постів"]);
            case "year":
                return formatCountRu(value, ["рік", "роки", "років"]);
            case "month":
                return formatCountRu(value, ["місяць", "місяці", "місяців"]);
            case "day":
                return formatCountRu(value, ["день", "дні", "днів"]);
            case "hour":
                return formatCountRu(value, ["година", "години", "годин"]);
            case "minute":
                return formatCountRu(value, ["хвилина", "хвилини", "хвилин"]);
            case "second":
                return formatCountRu(value, ["секунда", "секунди", "секунд"]);
            case "review":
                return formatCountRu(value, ["відгук", "відгуки", "відгуків"]);
            case "reply":
                return formatCountRu(value, ["відповідь", "відповіді", "відповідей"])
            case "people":
                return formatCountRu(value, ["людина", "людей", "чоловік"])
            case "department":
                return formatCountRu(value, ["відділ", "відділи", "відділів"])
            case "document":
                return formatCountRu(value, ["документ", "документи", "документів"])
            case 'course':
                return formatCountRu(value, ["курс", "курса", "курсів"])
            case 'view':
                return formatCountRu(value, ["перегляд", "перегляди", "переглядів"])
            default:
                return
        }
    }

    if (lang === "kk") {
        switch (title) {
            case "reply":
                return formatCountRu(value, ["reply", "replies", "replies"])
            default:
                return value === 1 ? `${value} ${title}` : `${value} ${title}s`;
        } 
    }

    if (lang === "it") {
        switch (title) {
            case "module":
                return formatCountRu(value, ["modulo", "moduli", "moduli"]);
            case "lesson":
                return formatCountRu(value, ["lezione", "lezioni", "lezioni"]);
            case "question":
                return formatCountRu(value, ["domanda", "domande", "domande"]);
            case "post":
                return formatCountRu(value, ["post", "post", "post"]);
            case "year":
                return formatCountRu(value, ["anno", "anni", "anni"]);
            case "month":
                return formatCountRu(value, ["mese", "mesi", "mesi"]);
            case "day":
                return formatCountRu(value, ["giorno", "giorni", "giorni"]);
            case "hour":
                return formatCountRu(value, ["ora", "ore", "ore"]);
            case "minute":
                return formatCountRu(value, ["minuto", "minuti", "minuti"]);
            case "second":
                return formatCountRu(value, ["secondo", "secondi", "secondi"]);
            case "review":
                return formatCountRu(value, ["recensione", "recensioni", "recensioni"]);
            case "reply":
                return formatCountRu(value, ["risposta", "risposte", "risposte"]);
            case "people":
                return formatCountRu(value, ["persona", "persone", "persone"]);
            case "department":
                return formatCountRu(value, ["dipartimento", "dipartimenti", "dipartimenti"]);
            case "document":
                return formatCountRu(value, ["documento", "documenti", "documenti"]);
            case 'course':
                return formatCountRu(value, ["corso", "corsi", "corsi"]);
            case 'view':
                return formatCountRu(value, ["visualizzazione", "visualizzazioni", "visualizzazioni"])
            default:
                return
        }
    }

    
    if (lang === "es") {
        switch (title) {
        case "module":
            return formatCountRu(value, ["módulo", "módulos", "módulos"]);
        case "lesson":
            return formatCountRu(value, ["lección", "lecciones", "lecciones"]);
        case "question":
            return formatCountRu(value, ["pregunta", "preguntas", "preguntas"]);
        case "post":
            return formatCountRu(value, ["publicación", "publicaciones", "publicaciones"]);
        case "year":
            return formatCountRu(value, ["año", "años", "años"]);
        case "month":
            return formatCountRu(value, ["mes", "meses", "meses"]);
        case "day":
            return formatCountRu(value, ["día", "días", "días"]);
        case "hour":
            return formatCountRu(value, ["hora", "horas", "horas"]);
        case "minute":
            return formatCountRu(value, ["minuto", "minutos", "minutos"]);
        case "second":
            return formatCountRu(value, ["segundo", "segundos", "segundos"]);
        case "review":
            return formatCountRu(value, ["reseña", "reseñas", "reseñas"]);
        case "reply":
            return formatCountRu(value, ["respuesta", "respuestas", "respuestas"]);
        case "people":
            return formatCountRu(value, ["persona", "personas", "personas"]);
        case "department":
            return formatCountRu(value, ["departamento", "departamentos", "departamentos"]);
        case "document":
            return formatCountRu(value, ["documento", "documentos", "documentos"]);
        case "course":
            return formatCountRu(value, ["curso", "cursos", "cursos"]);
        case "view":
            return formatCountRu(value, ["visualización", "visualizaciones", "visualizaciones"]);
        default:
                return
    }
}
 
}

export function timeSince(dateString, lang = "en") {
    const intervals = [
        { label: "year", seconds: 31536000 },
        { label: "month", seconds: 2592000 },
        { label: "day", seconds: 86400 },
        { label: "hour", seconds: 3600 },
        { label: "minute", seconds: 60 },
        { label: "second", seconds: 1 },
        { label: "second", seconds: 0 },
    ];
    const date = new Date(dateString);
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find((i) => i.seconds <= seconds);
    const count = interval?.seconds ? Math.floor(seconds / interval.seconds) : 0;
    return formatEndings(count, interval?.label, lang);
}

export const blobToBase64 = (blob) => {
    if(!blob){
        return
    }
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

export function setColorPalette(theme){
    if (theme?.title) {
            const { primary_color } = theme;
            const bg_color = theme.negate
                ? Color(theme.bg_color).negate().hex()
                : theme.bg_color;
            const text_color = theme.negate
                ? Color(theme.text_color).negate().hex()
                : theme.text_color;

            const pureWhite = theme.negate ? "#000000" : "#ffffff"

            const secondaryGray = Color(pureWhite)
                .mix(Color(bg_color))
                .mix(Color(text_color), 0.4)
                .hex();
            const secondaryLightPurple = Color(pureWhite)
                .mix(Color(bg_color))
                .mix(Color(primary_color), 0.16)
                .hex();
            const secondaryBackground = Color(pureWhite)
                .mix(Color(bg_color))
                .mix(Color(primary_color), 0.04);

            return {
                primary_color,
                bg_color,
                secondaryBackground,
                secondaryLightPurple,
                secondaryGray,
            };
        }
}

export const formatDate = (date, lang = "en") => {

    if(!date){
        return "-"
    }

    const d = new Date(date)
    const day = d.getDate()
    const month = d.toLocaleString(lang, { month: 'short' });
    const year = d.getFullYear()

    const newDate = `${day} ${month} ${year}`
 
    return newDate
 }

// check color luminance
 function getLuminance(r, g, b) {
    const a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// check color contrast ratio
function getContrastRatio(color1, color2) {
    const luminance1 = getLuminance(color1.r, color1.g, color1.b);
    const luminance2 = getLuminance(color2.r, color2.g, color2.b);
    return luminance1 > luminance2
        ? (luminance1 + 0.05) / (luminance2 + 0.05)
        : (luminance2 + 0.05) / (luminance1 + 0.05);
}

function hexToRgb(hex) { 
    let bigint = parseInt(hex.slice(1), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return { r, g, b };
}

// check color contrasting
export function isContrastingColor(userColorHex, themeColorHex) {
 
    const themeColor = hexToRgb(themeColorHex);
    const userColor = hexToRgb(userColorHex);

    const contrast = getContrastRatio(userColor, themeColor); 

    const isContrasting = contrast >= 4.5;
    return isContrasting; 
}

// set color palette

export function setNewColorPalette(theme) {
    const { primary_color } = theme
			//const primary_color = "#6B4DFF"
			 // const primary_color = "#FFEE00"
			const neutral = "#798086"

			const black = "#000000"
			const white = "#ffffff"

			const blue = "#17B4E5"
			const green = "#00CC66"
			const yellow = "#FFBB33"
			const orange = "#FF6600"
			const red  = "#FF4E6B"

			const lightTheme = !theme.negate

			//primitives

			const brand500 = Color(primary_color);

			const brand100 = Color(black).mix(brand500, 0.16).hex();
			const brand200 = Color(black).mix(brand500, 0.3).hex();
			const brand300 = Color(black).mix(brand500, 0.6).hex();
			const brand400 = Color(black).mix(brand500, 0.80).hex();
			const brand600 = Color(white).mix(brand500, 0.7).hex();
			const brand700 = Color(white).mix(brand500, 0.4).hex();
			const brand800 = Color(white).mix(brand500, 0.2).hex();
			const brand900 = Color(white).mix(brand500, 0.04).hex();

			const neutral500 = Color(neutral)

			const neutral0 = black;
			const neutral100 = Color(black).mix(neutral500, 0.16).hex();
			const neutral200 = Color(black).mix(neutral500, 0.3).hex();
			const neutral300 = Color(black).mix(neutral500, 0.6).hex();
			const neutral400 = Color(black).mix(neutral500, 0.80).hex();
			const neutral600 = Color(white).mix(neutral500, 0.7).hex();
			const neutral700 = Color(white).mix(neutral500, 0.4).hex();
			const neutral800 = Color(white).mix(neutral500, 0.2).hex();
			const neutral900 = Color(white).mix(neutral500, 0.04).hex();
			const neutral1000 = white;

			const blue500 = Color(blue) 
			const blue600 = Color(white).mix(blue500, 0.7).hex(); 
			const green500 = Color(green)  
			const green600 = Color(white).mix(green500, 0.7).hex();
			const yellow500 = Color(yellow)   
			const yellow600 = Color(white).mix(yellow500, 0.7).hex(); 
			const orange500 = Color(orange)  
			const orange600 = Color(white).mix(orange500, 0.7).hex();
			const red500 = Color(red)  
			const red600 = Color(white).mix(red500, 0.7).hex();

			//variables
			//surface
			const surfaceBrand = lightTheme ? brand500 : brand600
			const surfacePrimary = lightTheme ? neutral1000 : neutral200
			const surfaceSecondary = lightTheme ? brand900 : neutral100
			const surfaceTertiary = lightTheme ? brand800 : neutral300
			const surfaceContrast = lightTheme ? neutral600 : neutral500
			const surfaceHightContrast = lightTheme ? neutral400 : neutral700
			const surfaceInvert = lightTheme ? neutral0 : neutral1000
			const surfaceAi = lightTheme ? blue500 : blue600
			const surfacePositive = lightTheme ? green500 : green600
			const surfaceWarning = lightTheme ? yellow500 : yellow600
			const surfaceLimit = lightTheme ? orange500 : orange600
			const surfaceNegative = lightTheme ? red500 : red600

			//contrast
			const lowContrast = !isContrastingColor(brand500.hex(), surfacePrimary);
			 
			// text
			const textBrand = !lowContrast ? surfaceBrand : surfaceInvert
			const textPrimary = surfaceInvert
			const textSecondary = surfaceHightContrast
			const textTertiary = surfaceContrast
			const textInvert = surfacePrimary
			const textButton = lightTheme ? (!lowContrast ? neutral1000 : neutral0) : (!lowContrast ? neutral0 : neutral1000) 
			const textBadges = neutral0;
			const textAi = surfaceAi
			const textPositive = surfacePositive
			const textWarning = surfaceWarning
			const textLimit = surfaceLimit
			const textNegative = surfaceNegative

			//borders 
			const borderBrand = !lowContrast ? surfaceBrand : surfaceContrast 
			const borderBrandSecondary = surfaceTertiary
			const borderBrandTertiary = surfaceSecondary
			const borderPrimary = surfacePrimary
			const borderSecondary = surfaceContrast
			const borderTertiary = surfaceHightContrast
			const borderInvert = surfaceInvert
			const borderAi = surfaceAi
			const borderPositive = surfacePositive
			const borderWarning = surfaceWarning
			const borderLimit = surfaceLimit
			const borderNegative = surfaceNegative

			// icons
			const iconBrand = textBrand
			const iconPrimary = textPrimary
			const iconSecondary = textSecondary
			const iconTertiary = textTertiary
			const iconInvert = textInvert
			const iconButton = lightTheme ? (!lowContrast ? neutral1000 : neutral0) : (!lowContrast ? neutral0 : neutral1000)
			const iconAi = textAi
			const iconPositive = textPositive
			const iconWarning = textWarning
			const iconLimit = textLimit
			const iconNegative = textNegative

// brand button
			const buttonDisabledAll = !lowContrast ? neutral800 : neutral300
			const buttonEnabled = brand500
			const buttonHovered = brand600
			const buttonFocused = brand600
			const buttonPressed = brand700
			const buttonSecondaryEnabled = lightTheme ? (!lowContrast ? brand900 : neutral900) : (!lowContrast ? brand200 : neutral300) 
			const buttonSecondaryHovered = lightTheme ? (!lowContrast ? brand800 : neutral800) : (!lowContrast ? brand300 : neutral400) 
			const buttonSecondaryFocused = lightTheme ? (!lowContrast ? brand800 : neutral800) : (!lowContrast ? brand300 : neutral400) 
			const buttonSecondaryPressed = lightTheme ? (!lowContrast ? brand700 : neutral700) : (!lowContrast ? brand400 : neutral500) 

			//elevation (shadow)
			//перевірити
			const elevationNone = Color(neutral0).alpha(0).hex()
			const elevation1 = lightTheme ? Color(neutral0).alpha(0.04).hex() : Color(neutral0).alpha(0.2).hex()
			const elevation2 =  lightTheme ? Color(neutral0).alpha(0.04).hex() : Color(neutral0).alpha(0.2).hex()
			const elevation3 =  lightTheme ? Color(neutral0).alpha(0.08).hex() : Color(neutral0).alpha(0.4).hex()
			const elevation4 =  lightTheme ? Color(neutral0).alpha(0.12).hex() : Color(neutral0).alpha(0.6).hex()
			const elevation5 =  lightTheme ? Color(neutral0).alpha(0.16).hex() : Color(neutral0).alpha(0.8).hex()

            const { body } = document
			body.style = ` 

			--surfaceBrand: ${surfaceBrand}; 
			--surfacePrimary: ${surfacePrimary}; 
			--surfaceSecondary: ${surfaceSecondary};  
			--surfaceTertiary: ${surfaceTertiary}; 
			--surfaceContrast: ${surfaceContrast}; 
			--surfaceHightContrast: ${surfaceHightContrast}; 
			--surfaceInvert: ${surfaceInvert}; 
			--surfaceAi: ${surfaceAi};  
			--surfacePositive: ${surfacePositive};
			--surfaceWarning: ${surfaceWarning}; 
			--surfaceLimit: ${surfaceLimit};  
			--surfaceNegative: ${surfaceNegative};

			--textBrand: ${textBrand}; 
			--textPrimary: ${textPrimary}; 
			--textSecondary: ${textSecondary};  
			--textTertiary: ${textTertiary};  
			--textInvert: ${textInvert}; 
			--textButton: ${textButton};  
			--textBadges: ${textBadges}; 
			--textAi: ${textAi};  
			--textPositive: ${textPositive};
			--textWarning: ${textWarning}; 
			--textLimit: ${textLimit};  
			--textNegative: ${textNegative};

			--borderBrand: ${borderBrand};  
			--borderBrandSecondary: ${borderBrandSecondary};
			--borderBrandTertiary: ${borderBrandTertiary};  
			--borderPrimary: ${borderPrimary}; 
			--borderSecondary: ${borderSecondary}; 
			--borderTertiary: ${borderTertiary}; 
			--borderInvert: ${borderInvert}; 
			--borderAi: ${borderAi};  
			--borderPositive: ${borderPositive};
			--borderWarning: ${borderWarning}; 
			--borderLimit: ${borderLimit};  
			--borderNegative: ${borderNegative};

			--iconBrand: ${iconBrand}; 
			--iconPrimary: ${iconPrimary}; 
			--iconSecondary: ${iconSecondary};  
			--iconTertiary: ${iconTertiary};  
			--iconInvert: ${iconInvert}; 
			--iconButton: ${iconButton};   
			--iconAi: ${iconAi};  
			--iconPositive: ${iconPositive}; 
			--iconWarning: ${iconWarning}; 
			--iconLimit: ${iconLimit};  
			--iconNegative: ${iconNegative};
						
			--buttonDisabledAll: ${buttonDisabledAll};
			--buttonEnabled: ${buttonEnabled}; 
			--buttonHovered: ${buttonHovered}; 
			--buttonFocused: ${buttonFocused}; 
			--buttonPressed: ${buttonPressed};
			--buttonSecondaryEnabled: ${buttonSecondaryEnabled}; 
			--buttonSecondaryHovered: ${buttonSecondaryHovered}; 
			--buttonSecondaryFocused: ${buttonSecondaryFocused};  
			--buttonSecondaryPressed: ${buttonSecondaryPressed}; 

			--elevation1: ${elevation1};
			--elevation2: ${elevation2}; 
			--elevation3: ${elevation3}; 
			--elevation4: ${elevation4}; 
			--elevation5: ${elevation5};
			`
}