import classNames from "classnames"
import { useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import LangSwitcher from "../../LangSwitcher"
import JustifyContainer from "../JustifyContainer"
import Logo from "../Logo.svg"
import styles from "./header.module.sass"
import { globalLoadSelector } from "../../../store/reducers/loader"
import {useSelector} from 'react-redux'

export default function HeadarAuth({ logo }) {
	const img = logo ? logo : Logo
	const location = useLocation()

	const loading = useSelector(globalLoadSelector).loading

	const hideLang = useMemo(() => {
		return location.pathname === "/set-password"
	}, [location.pathname])

	if(loading) {
		return null
	}
	return (
		<header className={classNames(styles.header, styles.header__auth)}>
			<JustifyContainer className={styles.header__container}>
				<Link className={styles.header__logo} to="/products">
					<img src={img} alt="Logo" />
					{!logo && (
						<span
							className={styles.header__logoText}
						>
							sereda.ai
						</span>
					)}
				</Link>

				<div className={styles.header__right}>
					{!hideLang && <LangSwitcher />}
					{/*<LangSwitcher />*/}
				</div>
			</JustifyContainer>
		</header>
	)
}
