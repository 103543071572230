import { LoadIcon } from "../KnowledgeBase/SearchAi/LoadIcon";
import styles from "./preloader.module.sass";

export default function PreloaderMini() {
  return (
    <>
      <LoadIcon className={styles.preloader__mini} /> 
    </>
  );
}
