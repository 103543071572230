import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { lastNotificationsSelector } from "../../store/reducers/notifications";
import MobileTitle from "../Mobile/MobileTitle";
import Typography from "../UIKit/base/Typography";
import { timeSince } from "../utils";
import NoItems from "./NoItems";
import styles from "./Notifications.module.sass";
import * as Icons from "./NotificationsIconPack/icons";
import { CloseIcon } from "../icons";
import { getFileLink } from "../../store/reducers/knowledgeBase";

const NotificationsPopup = ({ closePopup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const notificationsData = useSelector(lastNotificationsSelector);
  const notifications = notificationsData?.notifications;

  const handleDownload = (documentId, filename) => {
		dispatch(getFileLink({documentId, filename}))
	}

  return (
    <>
      <div className={styles.notificationsPopup}>
        {/* <MobileTitle showBackArrow={true} title={t("notifications")} /> */}
        <div className={styles.notificationsPopup__header}>
          <Typography>{t("notifications")}</Typography>
          <div className={styles.notificationsPopup__close} onClick={closePopup}>
            {" "}
            <CloseIcon />
          </div>
        </div>

        <div className={styles.notificationsPopup__link}>
          <Typography
            component={Link}
            fontVariant="semiBold"
            to={`/notifications`}
            onClick={closePopup}
          >
            {t("view_all_notifications")}
          </Typography>
        </div>

        {!!notifications?.length &&
          notifications.map((item) => {
            const Icon = Icons[item.type] ? Icons[item.type] : Icons["custom"];
 
            return (
              <div className={styles.notificationPopup} key={item.id}>
                <div
                  className={styles[`notificationPopup__icon_${item.color}`]}
                >
                  <Icon />
                </div>
                <div className={styles.notificationPopup__body}>
                  <div className={styles.notificationPopup__text}>
                    {item.type === "custom" && (
                      <Typography fontVariant="">{item.text}</Typography>
                    )}
                    {item.type !== "custom" && item.type !== "survey" && item.type !== "kb_doc_access"  && item.type !== "kb_folder_access" && (
                      <>
                        {item.link && (
                          <Typography
                            component={Link}
                            to={item.link}
                            fontVariant="normal"
                          >
                            {item.action}
                          </Typography>
                        )}
                        {!item.link && (
                          <Typography fontVariant="normal">
                            {item.action}
                          </Typography>
                        )}
                      </>
                    )}
                    {item.type === "survey" && (
                      <Typography
                        component={Link}
                        to={item.link}
                        fontVariant="normal"
                      >
                        {t(item.action)}
                      </Typography>
                    )}
                    {(item.type === "kb_doc_access" || item.type === "kb_folder_access") && (
                      <>
                      
                        {item.link && !item.document_type && (
                          <Typography
                            component={Link}
                            to={item.link}
                            fontVariant="normal"
                          >
                            {item.title}
                          </Typography>
                        )}
                        {item.link && !!item.document_type && (
                          <Typography
                            component="span" 
                            fontVariant="normal"
                            onClick={() => handleDownload(item.entity_id, item.document_name)}
                          >
                            {item.action}
                          </Typography>
                        )}
                        {!item.link && (
                          <Typography fontVariant="normal">
                            {item.title}
                          </Typography>
                        )}
                      </>
                    )}
                    
                  </div>

                  <div className={styles.notificationPopup__bottom}>
                    {!item.viewed && (
                      <div className={styles.notificationPopup__viewed}></div>
                    )}
                    <div className={styles.notificationPopup__time}>
                      {timeSince(item.sent_at, i18next.language)} {t("ago")}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        {!notifications?.length && (
          <div className={styles.notificationsPopup__noItems}>
            <NoItems />
          </div>
        )}
      </div>
      <div className={styles.overlay} onClick={closePopup}></div>
    </>
  );
};

export default NotificationsPopup;
