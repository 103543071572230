import axios from "axios"
import { isPast } from "date-fns"
import i18next from "i18next"
import jwt_decode from "jwt-decode"

const BASE_URL = process.env.REACT_APP_END_POINT_BD
const URLS = {}
export default class Api {
	constructor(options = {}) {
		this.logout = options.logout
		this.errorMessage = options.errorMessage
		this.errorNetwork = options.errorNetwork
		this.instance = axios.create({
			// withCredentials: true, TODO: CORS
			baseURL: BASE_URL,
		})
		this.refresh = null
		this.instance.interceptors.request.use((config) => {
			if (this.token) {
				config.headers.Authorization = `Bearer ${this.token}`
			} else {
				delete config.headers["Authorization"]
			}
			config.signal = URLS[config.url]
			return config
		})

		this.instance.interceptors.response.use(
			(config) => {
				return config
			},
			(err) => {
				if (axios.isCancel(err)) {
					return Promise.reject("User cancel the network request.")
				}

				if(err.code ==='ERR_NETWORK'){
					this.errorNetwork(i18next.t('ERR_NETWORK'))
					throw err
				}
				if (
					err.request.responseType === "blob" &&
					err.response.data instanceof Blob &&
					err.response.data.type &&
					// eslint-disable-next-line eqeqeq
					err.response.data.type.toLowerCase().indexOf("json") != -1
				) {
					return new Promise((resolve, reject) => {

						let reader = new FileReader()
						reader.onload = () => {
							err.response.data = JSON.parse(reader.result)
							this.errorMessage(err.response.data?.message)
							resolve(Promise.reject(err))
						}

						reader.onerror = () => {
							reject(err)
						}

						reader.readAsText(err.response.data)
					})
				}
				if (err?.response?.status === 401) {
					this.logout()
				}

				if (
					(err?.response?.status === 422 || err?.response?.status === 403) &&
					window.location.pathname !== "/login" &&
					err?.response?.data?.message
				) {
					if (err?.response?.data?.validation_errors) {
						this.errorMessage(
							err?.response?.data?.message,
							Object.values(err?.response?.data?.validation_errors)?.flat()
						)
					} else {
						this.errorMessage(err?.response?.data?.message)
					}
				} 

				if (err?.response?.status >= 400) {
					throw err
				}

				return err
			}
		)
	}

	refreshValidation = () => {
		const access = localStorage.getItem("token")
		try {
			const decode = jwt_decode(access)

			const exp = decode.exp * 1000 - 10000 //Date - 10sek
			this.token = access
			if (isPast(new Date(exp), new Date()) && !this.refresh) {
				this.refresh = this.refreshQuery(access)
			}
		} catch (err) {
			localStorage.removeItem("token")
			this.token = null
		}
	}
	refreshQuery = async () => {
		const refresh_token = localStorage.getItem("refresh_token")
		await new Promise((res, rej) => {
			axios
				.post(`${BASE_URL}/v1/refresh`, {
					refresh_token,
				})
				.then(({ data: { authorization } }) => {
					if (authorization?.access_token && authorization?.refresh_token) {
						localStorage.setItem("token", authorization?.access_token)
						localStorage.setItem("refresh_token", authorization?.refresh_token)
					}
					this.token = authorization.access_token
					res((this.refresh = null))
				})
				.catch((err) => {
					this.logout()
					rej((this.refresh = null))
				})
		})
	}

	request = async (url, method, data, options) => {
		this.refreshValidation()
		// this.token = localStorage.getItem("token")TODO:
		if (!URLS[url]) {
			URLS[url] = options?.signal
		}
		try {
			if (this.refresh) {
				await this.refresh
			}
			const response = await this.instance[method](url, data, options)
			delete URLS[url]
			if (response.code === "ERR_NETWORK") {
				throw response
			}
			return response
		} catch (err) {
			delete URLS[url]
			throw err
		}
	}

	get = async (url, data = null, options = null) =>
		await this.request(url, "get", data, options)

	post = async (url, data = null, options = null) =>
		await this.request(url, "post", data, options)

	put = async (url, data = null, options = null) =>
		await this.request(url, "put", data, options)

	patch = async (url, data = null, options = null) =>
		await this.request(url, "patch", data, options)

	delete = async (url, data = null, options = null) =>
		await this.request(url, "delete", data, options)

	options = async (url, data = null, options = null) =>
		await this.request(url, "options", data, options)
}
