import { api } from "../http"

export async function getCompanyStructureRequest() {
	const url = `/v1/knowledge-base/company-structure`
	return await api.get(url)
}

export async function getDocumentsRequest() {
	const url = `/v1/knowledge-base/documents`
	return await api.get(url)
}

export async function getDocumentRequest({ documentId }) {
	const url = `/v1/knowledge-base/documents/${documentId}`
	return await api.get(url)
}

export async function searchRequest({ search, options }) {
	const url = `/v1/knowledge-base/documents/search?search=${search}`
	return await api.get(url, null, options)
}

export async function searchAiRequest({ queryString }, options) {
	const url = `/v1/knowledge-base/documents/search?${queryString}`
	return await api.get(url, null, options)
}

export async function getTranslationsListRequest({ documentId }) {
	const url = `/v1/knowledge-base/documents/${documentId}/translations`
	return await api.get(url)
}

export async function getTranslationDocumentRequest({ documentId }) {
	const url = `/v1/knowledge-base/translations/${documentId}`
	return await api.get(url)
}

export async function getFileLinkRequest({ documentId }) {
	const url = `/v1/knowledge-base/get-download-link/${documentId}`
	return await api.get(url, {responseType: "blob"})
}

export async function downloadFileRequest({ url }) {
	return await api.get(url, {responseType: "blob"})
}