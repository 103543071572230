import { useTranslation } from "react-i18next"
import styles from "./index.module.sass"
import { useDispatch, useSelector } from "react-redux"
import { cloneElement, useEffect, useMemo, useRef, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
	documentArticlesSelector,
	documentSelector,
	documentTextSelector,
	documentsSelector,
	getDocument,
	getDocuments,
	getTranslationDocument,
	getTranslationsList,
	resetDocument,
	setTranslationsList,
	translationListSelector,
} from "../../../store/reducers/knowledgeBase"
import { errorSelector } from "../../../store/reducers/course"
import {
	resetBreadcrumbs,
	setBreadcrumbs,
} from "../../../store/reducers/breadcrumbs"
import MobileTitle from "../../Mobile/MobileTitle"
import Typography from "../../UIKit/base/Typography"

import classNames from "classnames"

import EditorView from "../../UIKit/editor/view/EditorView"
import Page404 from "../../404"
import i18next from "i18next"
import ScrollToHashElement, {
	scrollIntoViewWithOffset,
} from "../../link/ScrollToHash"
import { ArrowDownIcon, CloseIcon } from "../../icons"
import { formatEndings } from "../../utils"
import {
	globalLoadSelector,
	setShowLoader,
} from "../../../store/reducers/loader"
import Preloader from "./Preloader"

export default function Document() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [modal, setModal] = useState("")
	const ref = useRef(null)

	const { documentId } = useParams()

	const doc = useSelector(documentSelector)
	const documents = useSelector(documentsSelector)
	const articles = useSelector(documentArticlesSelector)
	const text = useSelector(documentTextSelector)
	const loading = useSelector(globalLoadSelector).loading

	const navigate = useNavigate()

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])

	const related = useMemo(() => {
		if (!documents) {
			return []
		}
		const res = documents.reduce((acc, d) => {
			const file = d?.kb_documents_list.filter(
				(e) =>
					e.department_id === doc?.kb_document?.department_id &&
					e.document_id !== +documentId
			)
			acc = [...acc, ...file]
			return acc
		}, [])
		return res?.length > 3 ? res.slice(0, 3) : res
	}, [documents, doc, documentId])

	const error = useSelector(errorSelector)

	const crumbs = useMemo(() => {
		const arr = [
			{ path: `/knowledge-base`, title: t("knowledge_base") },
			{
				path: `/knowledge-base/:documentId`,
				title: doc?.kb_document?.name,
				ellipsis: true,
			},
		]

		return arr
	}, [doc, t])

	useEffect(() => {
		if (error !== 404) {
			dispatch(setBreadcrumbs(crumbs))
		}
		return () => {
			dispatch(resetBreadcrumbs())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crumbs, dispatch])

	useEffect(()=>{
		if(doc?.kb_document){
			dispatch(getTranslationsList({ documentId: doc?.kb_document?.parent_id || doc?.kb_document?.id }))
		}
		return ()=>{
			dispatch(setTranslationsList(null))
		}
	},[dispatch, doc?.kb_document])

	useEffect(() => {
		dispatch(getDocuments())
		dispatch(getDocument({ documentId, navigate }))
		return () => {
			dispatch(resetDocument())
			
		}
	}, [dispatch, documentId])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [documentId])

	if (error === 404 || error === 422) {
		return <Page404 />
	}

	return (
		<>
			<MobileTitle title={doc?.kb_document?.name} showBackArrow={true} />
			<ScrollToHashElement />
			{loading ? <Preloader /> : null}
			<ul className={styles.breadcrumbs__wrapper}>
				<BreadcrumbsMobile name={doc?.kb_document?.name}>
					<li>...</li>
				</BreadcrumbsMobile>
				<li>
					<Typography
						component={Link}
						className={styles.breadcrumbs}
						to="/knowledge-base"
					>
						{t("knowledge_base")}
					</Typography>
				</li>
			</ul>
			<div className={styles.wrapper}>
				<div className={styles.right}>
					<div className={styles.sticky}>
						<Titles articles={articles} modal={modal} setModal={setModal} />
						<Translates
							lng={doc?.kb_document?.main_language}
							modal={modal}
							setModal={setModal}
						/>
					</div>
				</div>
				<div className={styles.content}>
					<div className={styles.header}>
						<Typography fontVariant="medium">
							<span>{t("document")}</span>
							<span>
								{t("published")}{" "}
								{doc?.kb_document?.created_at &&
									formatDate(doc?.kb_document?.created_at, i18next.language)}
							</span>
							{!!doc?.kb_document?.statistics?.views &&
								<span className={styles.document__views}>
									{formatEndings(doc?.kb_document?.statistics?.views, "view", i18next.language)}
								</span>
							}
						</Typography>
						<Typography
							fontVariant="bold"
							component="h3"
							className={styles.title}
						>
							{doc?.kb_document?.name}
						</Typography>
					</div>
					<EditorView content={text} ref={ref} />
				</div>
			</div>
		</>
	)
}

export const formatDate = (date, lang = "en") => {
	const d = new Date(date)

	const day = d.getDate()
	const month = d.toLocaleString(lang, { month: "short" })
	const year = d.getFullYear()
	const newDate = `${day} ${month} ${year}`

	return newDate
}

function Titles({ articles, modal, setModal }) {
	const { pathname, hash } = useLocation()
	const namespace = "titles"
	function handleClick({ currentTarget }) {
		const href = currentTarget.dataset.href
		const id = href.slice(1, href?.length)
		if (id && hash === href) {
			const element = document.getElementById(id)
			if (element) {
				if (window.clientWidth > 768) {
					element.scrollIntoView({
						behavior: "smooth",
						// block: "end",
						inline: "nearest",
					})
				} else {
					scrollIntoViewWithOffset(element, 81)
				}
			}
		}
	}

	if (!articles?.length) {
		return null
	}

	return (
		<Dropdown
			modal={modal === namespace}
			setModal={setModal}
			label="table_of_contents"
			disabled={!articles?.length}
			namespace={namespace}
		>
			<ul className={styles.list}>
				{articles?.length > 0 &&
					articles.map((a, i) => {
						if (a?.title) {
							return (
								<li
									className={classNames(styles.list__item, {
										[styles.list__item_active]: hash === a?.href,
									})}
									key={a?.href + "" + i}
									onClick={handleClick}
									data-href={a?.href}
								>
									<Typography
										style={{ paddingLeft: a?.gap + "px" }}
										component={Link}
										to={`${pathname}${a?.href}`}
									>
										{a?.title}
									</Typography>
								</li>
							)
						}
						return null
					})}
			</ul>
		</Dropdown>
	)
}

function Translates({ lng, modal, setModal }) {
	const list = useSelector(translationListSelector)
	const dispatch = useDispatch()
	const namespace = "translates"

	function handleClick({ currentTarget }) {
		const documentId = currentTarget.dataset.id
		dispatch(getTranslationDocument({ documentId }))
	}

	if (!list?.length) {
		return null
	}

	return (
		<Dropdown
			modal={modal === namespace}
			setModal={setModal}
			label="translations"
			disabled={!list?.length}
			namespace={namespace}
		>
			<ul className={styles.list}>
				{list?.length > 0 &&
					list.map((l, i) => {
						if (l?.language_name) {
							return (
								<li
									className={classNames(styles.list__item, {
										[styles.list__item_active]: lng === l?.language_code,
									})}
									key={l?.id}
									data-id={l?.id}
									onClick={handleClick}
								>
									<Typography component="a">{l?.language_name}</Typography>
								</li>
							)
						}
						return null
					})}
			</ul>
		</Dropdown>
	)
}

function Dropdown({
	children,
	label,
	icon: Icon,
	disabled = false,
	modal,
	setModal,
	namespace,
}) {
	const { t } = useTranslation()
	return (
		<div className={styles.dropdown}>
			<Typography
				onClick={() => {
					if (disabled) return
					setModal((s) => {
						if (s === namespace) {
							return ""
						}
						return namespace
					})
				}}
				fontVariant="medium"
				className={styles.dropdown__label}
			>
				{Icon ? <Icon /> : null}
				{t(label)}
				<svg
					className={classNames(styles.dropdown__arrow, {
						[styles.dropdown__arrow_active]: modal,
					})}
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10 13.9998C9.41668 13.9998 8.83335 13.7748 8.39168 13.3331L2.95835 7.89979C2.71668 7.65812 2.71668 7.25812 2.95835 7.01646C3.20002 6.77479 3.60002 6.77479 3.84168 7.01646L9.27502 12.4498C9.67502 12.8498 10.325 12.8498 10.725 12.4498L16.1583 7.01646C16.4 6.77479 16.8 6.77479 17.0417 7.01646C17.2834 7.25812 17.2834 7.65812 17.0417 7.89979L11.6084 13.3331C11.1667 13.7748 10.5834 13.9998 10 13.9998Z"
						fill="#686670"
					/>
				</svg>
			</Typography>
			<div
				className={classNames(styles.dropdown__papper, {
					[styles.dropdown__papper_toggle]: modal,
				})}
			>
				<div className={styles.dropdown__papper_wrapper}>{children}</div>
			</div>
		</div>
	)
}

function BreadcrumbsMobile({ children, name }) {
	const { t } = useTranslation()
	const [toggle, setToggle] = useState(false)
	return (
		<>
			{cloneElement(children, {
				onClick: () => {
					setToggle((s) => !s)
				},
			})}
			{toggle && (
				<div className={styles.modal__wrapper}>
					<div className={styles.modal__header}>
						<button
							type="button"
							onClick={() => {
								setToggle(false)
							}}
						>
							<CloseIcon />
						</button>
					</div>
					<ul className={styles.modal__content}>
						<li>
							<Typography
								fontVariant="medium"
								to="/knowledge-base"
								component={Link}
							>
								{t("knowledge_base")}
							</Typography>
						</li>
						<li>
							<ArrowDownIcon />
						</li>
						<Typography fontVariant="medium" component="li">
							{name}
						</Typography>
					</ul>
				</div>
			)}
		</>
	)
}
