import classNames from "classnames"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { logout } from "../../../store/reducers/auth"
import { negateSelector } from "../../../store/reducers/domainTheme"
import {
	getUser,
	menuSelector,
	personSelector,
} from "../../../store/reducers/user"
import Logo from "../../Logo/Logo"
import { NotificationsButton } from "../../Notifications/NotificationsButton"
import Avatar from "../../UIKit/avatarView/AvatarView"
import Typography from "../../UIKit/base/Typography"
import useClickOutside from "../../hooks/useClickOutside"
import {
	ArrowRightIcon,
	BookIcon,
	CloseIcon,
	DashboardIcon,
	DocumentTextIcon,
	KnowbaseIcon,
	TelegramIcon,
	ViberIcon,
	WhatsappIcon,
} from "../../icons"
import CustomLink from "../../link/CustomLink"
import JustifyContainer from "../JustifyContainer"
import NegateChangeTheme from "./NegateChangeTheme"
import styles from "./header.module.sass"
import { globalLoadSelector } from "../../../store/reducers/loader"
import PreloaderCircle from "../../Preloader/PreloaderTemplates/PreloaderCircle"
import PreloaderHeader from "../../Preloader/Preloaders/PreloaderHeader"

export function SurveysIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M8 22.3194C7.72 22.3194 7.42998 22.2494 7.16998 22.1094C6.59998 21.8094 6.25 21.2094 6.25 20.5694V19.1495C3.23 18.8395 1.25 16.6194 1.25 13.4394V7.43945C1.25 3.99945 3.56 1.68945 7 1.68945H17C20.44 1.68945 22.75 3.99945 22.75 7.43945V13.4394C22.75 16.8794 20.44 19.1894 17 19.1894H13.23L8.96997 22.0295C8.67997 22.2195 8.34 22.3194 8 22.3194ZM7 3.17944C4.42 3.17944 2.75 4.84944 2.75 7.42944V13.4295C2.75 16.0095 4.42 17.6795 7 17.6795C7.41 17.6795 7.75 18.0195 7.75 18.4295V20.5595C7.75 20.6895 7.83 20.7495 7.88 20.7795C7.93001 20.8095 8.03001 20.8395 8.14001 20.7695L12.59 17.8095C12.71 17.7295 12.86 17.6795 13.01 17.6795H17.01C19.59 17.6795 21.26 16.0095 21.26 13.4295V7.42944C21.26 4.84944 19.59 3.17944 17.01 3.17944H7Z" />
			<path d="M11.9998 12.1095C11.5898 12.1095 11.2498 11.7695 11.2498 11.3595V11.1495C11.2498 9.98954 12.0998 9.41952 12.4198 9.19952C12.7898 8.94952 12.9098 8.77953 12.9098 8.51953C12.9098 8.01953 12.4998 7.6095 11.9998 7.6095C11.4998 7.6095 11.0898 8.01953 11.0898 8.51953C11.0898 8.92953 10.7498 9.26953 10.3398 9.26953C9.92984 9.26953 9.58984 8.92953 9.58984 8.51953C9.58984 7.18953 10.6698 6.1095 11.9998 6.1095C13.3298 6.1095 14.4098 7.18953 14.4098 8.51953C14.4098 9.65953 13.5698 10.2295 13.2598 10.4395C12.8698 10.6995 12.7498 10.8695 12.7498 11.1495V11.3595C12.7498 11.7795 12.4098 12.1095 11.9998 12.1095Z" />
			<path d="M12 14.5995C11.58 14.5995 11.25 14.2595 11.25 13.8495C11.25 13.4395 11.59 13.0995 12 13.0995C12.41 13.0995 12.75 13.4395 12.75 13.8495C12.75 14.2595 12.42 14.5995 12 14.5995Z" />
		</svg>
	)
}

const Header = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(getUser())
	}, [dispatch])

	if (loading) {
		return <PreloaderHeader />
	}
	return (
		<>
			<header className={styles.header}>
				<JustifyContainer className={styles.header__container}>
					<Link className={styles.header__logo} to="/">
						<Logo isHeader />
					</Link>
					<div className={styles.header__center}>
						<Typography
							component={CustomLink}
							fontVariant="medium"
							to="products"
							className={styles.header__link}
							activeClassName={styles.header__link_active}
						>
							{t("my_courses")}
						</Typography>
						<Typography
							component={CustomLink}
							fontVariant="medium"
							to="catalog"
							className={styles.header__link}
							activeClassName={styles.header__link_active}
						>
							{t("catalog")}
						</Typography>
						<Typography
							component={CustomLink}
							fontVariant="medium"
							to="knowledge-base"
							className={styles.header__link}
							activeClassName={styles.header__link_active}
						>
							{t("knowledge_base")}
						</Typography>
						<Typography
							component={CustomLink}
							fontVariant="medium"
							to="surveys"
							className={styles.header__link}
							activeClassName={styles.header__link_active}
						>
							{t("surveys")}
						</Typography>
					</div>
					<div className={styles.header__right}>
						<div className={styles.header__right_icon}>
							<NotificationsButton />
							{/* <NegateChangeTheme /> */}
						</div>
						<UserNavigation />
					</div>
				</JustifyContainer>
			</header>
		</>
	)
}

export default Header

export function UserNavigation() {
	const person = useSelector(personSelector)
	const [menu, setMenu] = useState(false)
	const ref = useRef(null)

	function handleMenu(e) {
		setMenu(true)
	}
	if (!person) {
		return null
	}

	return (
		<div className={styles.user__wrapper} ref={ref}>
			<Avatar
				name={person.name}
				src={person.avatar?.small}
				textClassName={styles.avatar__text}
				imgClassName={styles.avatar}
				onClick={handleMenu}
			/>
			<UserMenu
				person={person}
				isOpenMenu={menu}
				setIsopenMenu={setMenu}
				targetWrapper={ref}
			/>
		</div>
	)
}

function UserMenu({ person, targetWrapper, setIsopenMenu, isOpenMenu }) {
	const [closingAnimate, setClosingAnimate] = useState(false)
	const [showBots, setShowBots] = useState(false)
	const dispatch = useDispatch()
	const { pathname } = useLocation()

	const menu = useSelector(menuSelector)

	const { t } = useTranslation()
	const negate = useSelector(negateSelector)

	const close = useCallback(() => {
		setClosingAnimate(true)
		setTimeout(() => {
			setClosingAnimate(false)
			setIsopenMenu(false)
		}, 600)
	}, [setIsopenMenu, setClosingAnimate])

	useClickOutside(targetWrapper, close)

	useEffect(() => {
		close()
	}, [pathname, close])

	if (!isOpenMenu) {
		return null
	}

	return (
		<div
			className={classNames(styles.user__popower, {
				[styles.user__popower_close]: closingAnimate,
			})}
		>
			<div className={styles.user__popower_cancel} onClick={close}>
				<CloseIcon />
			</div>
			<div className={styles.user__popower_header}>
				<Avatar
					name={person.name}
					src={person?.avatar?.big}
					textClassName={classNames(
						styles.avatar__text,
						styles.avatar__text_big
					)}
					imgClassName={classNames(styles.avatar, styles.avatar_big)}
					Typography
					fontVariant="medium"
				/>
				<Typography fontVariant="medium" className={styles.user__name}>
					{person?.name}
				</Typography>
				<Typography fontVariant="medium" className={styles.user__email}>
					{person?.email}
				</Typography>
			</div>

			<ul className={styles.link__container}>
				{!!menu?.profile && (
					<li>
						<Typography
							component={Link}
							fontVariant=""
							className={styles.link}
							to="/profile"
						>
							{t("profile")}
						</Typography>
					</li>
				)}
				{!!menu?.bot_links && (
					<li
						className={styles.bots__wrapper}
						onClick={() => setShowBots((prev) => !prev)}
					>
						<div className={styles.bots__container}>
							<div
								className={classNames(styles.bots__container_connect, {
									[styles.bots__opened]: showBots,
								})}
							>
								<Typography
									component="p"
									fontVariant=""
									className={styles.link}
								>
									{t("connect_messenger")}
								</Typography>
								<ArrowRightIcon />
							</div>
							{window.innerWidth > 768 && <Bots bots={menu?.bot_links} />}
							{window.innerWidth <= 768 && showBots && (
								<Bots bots={menu?.bot_links} />
							)}
						</div>
					</li>
				)}
				{!!menu?.support && (
					<li>
						<Typography
							component={Link}
							fontVariant=""
							className={styles.link}
							to="/support"
						>
							{t("faq")}
						</Typography>
					</li>
				)}
				{!!menu?.logout && (
					<Typography
						component="li"
						fontVariant=""
						onClick={() => {
							dispatch(logout())
						}}
					>
						{t("log_out")}
					</Typography>
				)}
			</ul>
			<div className={styles.user__popower_negate}>
				{/*<NegateChangeTheme />*/}
			</div>
		</div>
	)
}

export const MenuMobile = () => {
	const location = useLocation()
	const path = location?.pathname
	const { t } = useTranslation()
	return (
		<>
			{window.innerWidth <= 1180 && (
				<div className={styles.headerMobile}>
					{/* <Link
						to="/"
						className={classNames(styles.headerMobile__item, {
							[styles.headerMobile__active]: path === "/",
						})}
					>
						<DashboardIcon />
						<Typography fontVariant="">{t("dashboard")}</Typography>
					</Link> */}
					<Link
						to="/knowledge-base"
						className={classNames(styles.headerMobile__item, {
							[styles.headerMobile__active]: path === "/knowledge-base" || path === "/knowledge-base/company-structure",
						})}
					>
						<KnowbaseIcon />
						<Typography fontVariant="">{t("knowledge_base")}</Typography>
					</Link>
					<Link
						to="/products"
						className={classNames(styles.headerMobile__item, {
							[styles.headerMobile__active]: path === "/products",
						})}
					>
						<BookIcon />
						<Typography fontVariant="">{t("my_courses")}</Typography>
					</Link>

					<Link
						to="/catalog"
						className={classNames(styles.headerMobile__item, {
							[styles.headerMobile__active]: path === "/catalog",
						})}
					>
						<DocumentTextIcon />
						<Typography fontVariant="">{t("catalog")}</Typography>
					</Link>
					
					<Link
						to="/surveys"
						className={classNames(styles.headerMobile__item, {
							[styles.headerMobile__active]: path === "/surveys",
						})}
					>
						<SurveysIcon />
						<Typography fontVariant="">{t("surveys")}</Typography>
					</Link>
				</div>
			)}
		</>
	)
}

const Bots = ({ bots }) => {
	const { t } = useTranslation()

	if (!bots || !Object.keys(bots)?.length) {
		return null
	}

	return (
		<div className={styles.bots}>
			<div className={styles.bots__list}>
				{Object.entries(bots).map(([key, value]) => (
					<Bot name={key} link={value} />
				))}
			</div>
		</div>
	)
}

const Bot = ({ name, link }) => {
	const { t } = useTranslation()
	return (
		<Link
			to={link}
			target="_blank"
			className={classNames(styles.bot, styles?.[name])}
		>
			{name === "telegram" && <TelegramIcon />}
			{name === "viber" && <ViberIcon />}
			{name === "whatsapp" && <WhatsappIcon />}
			<Typography>{t(name + "_bot")}</Typography>
		</Link>
	)
}
