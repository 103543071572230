import styles from "./404.module.sass";
import { Image404 } from "./404image";
import { useTranslation } from "react-i18next";
import Typography from "../UIKit/base/Typography";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";
import { errorSelector } from "../../store/reducers/domainTheme"
 
const Page404 = () => {
	const { t } = useTranslation();
const theme = useSelector(themeSelector);
const error = useSelector(errorSelector);

	return (
		<>
		{ (theme.title || error?.response?.status === 404 ) &&

			<Typography className={styles.page404}> 
				<Image404 />
				<h4>{t("page_not_found")}</h4>
				<p>{t("page_not_found_text")}</p>

				{theme.title && <Typography
					component={Link}
					className={styles.page404__button}
					to={`/`}
				>
					{t("back_to_project")}
				</Typography>}
			</Typography>
		}
	 </>
	);
};

export default Page404;
 