import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Typography from "../UIKit/base/Typography";
import { ClockIcon } from "../icons";
import { timeSince } from "../utils";
import styles from "./Notifications.module.sass";
import * as Icons from "./NotificationsIconPack/icons";
import { dispatch } from '../..';
import { getFileLink } from '../../store/reducers/knowledgeBase';

const NotificationsItem = ({ item }) => {
	const Icon = Icons[item.type]

	const { t } = useTranslation()

	return (
		<div className={styles.notification}>
			<div className={styles.notification__top}>
				<div className={styles.notification__type}>
					<div className={styles[`notification__icon_${item.color}`]}>
						{Icon && <Icon />}
					</div>
					<p>{t(item.type)}</p>
				</div>
				<div className={styles.notification__time}>
					<ClockIcon />
					{timeSince(item.sent_at, i18next.language)} {t("ago")}
				</div>
			</div>
			<div className={styles.notification__bottom}>
				<div className={styles.notification__text}>
					<TitleGenerator item={item} />
				</div>

				<div className={styles.notification__link}>
					<LinkGenerator item={item} />
				</div>
			</div>
		</div>
	)
}

export default NotificationsItem

function LinkGenerator(item) {
	const { t } = useTranslation()

	const { type, entity_id, entity_title, link, path_id, document_type, document_name } = item.item

	const handleDownload = (documentId, filename) => {
		dispatch(getFileLink({documentId, filename}))
	}

	const customLink = (link && type === "custom") ? (link?.split("://")?.[1]  ? link : `https://${link}` ) : "";
	const isFile = (!!document_type)
	return (
		<>
			{type === "products" && entity_title && (
				<Link to={`/products/${entity_id}${path_id ? `?filter[path_id]=${path_id}` : ""}`}>
					{t("go_to")} {entity_title}
				</Link>
			)}
			{type === "survey" && <Link to={link}>{t("go_survey")}</Link>}
			{type === "custom" && customLink && <a href={customLink}>{t("more")}</a>}
			{type === "kb_doc_access" && link && (isFile ? <span onClick={() => handleDownload(entity_id, document_name)}>{t("go_doc")}</span> : <a href={link}>{t("go_doc")}</a>)}
		</>
	)
}

function TitleGenerator(item) {
	const { t } = useTranslation()

	const { type, text, action, title } =
		item.item
	return (
		<>
		{type === "custom" && <Typography>{text}</Typography>}
		{type !== "custom" && type !== "survey" && type !== "kb_doc_access" && type !== "kb_folder_access" && <Typography>{action}</Typography>}
		{type === "survey" && <Typography>{`${t(action)} "${title}"`}</Typography>  }
		{(type === "kb_doc_access" || type === "kb_folder_access") && <Typography>{title}</Typography>  }
		</>
	)
}
