import classNames from "classnames"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useSearchParams } from "react-router-dom"
import {
	resetBreadcrumbs,
	setBreadcrumbs,
} from "../../store/reducers/breadcrumbs"
import {
	departmentDocumentsSelector,
	departmentSelector,
	getDepartment,
	getDepartmentDocuments,
	getPeopleOfDepartment,
	peopleOfDepartmentSelector,
	resetDepartment,
} from "../../store/reducers/department"

import { ArrowDownIcon, DocumentIcon } from "../icons"
import Avatar from "../UIKit/avatarView/AvatarView"
import Typography from "../UIKit/base/Typography"
import styles from "./Department.module.sass"
import { DepartmentIcon } from "./icon"
import LazyPaginator from "../UIKit/paginator/LazyPaginator"
import MobileHeader from '../Mobile/MobileHeader' 
import i18next from "i18next"
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader"
import PreloaderDepartment from "../Preloader/Preloaders/PreloaderDepartment"

export default function Department() {
	const { t } = useTranslation()
	const { departmentId } = useParams()
	const dispatch = useDispatch()
	const documents = useSelector(departmentDocumentsSelector)
	const people = useSelector(peopleOfDepartmentSelector)
	const department = useSelector(departmentSelector)

	const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])


	const crumbs = useMemo(() => {
		const arr = [
			{ path: `/knowledge-base`, title: t("knowledge_base") },
			{
				path: `/knowledge-base/company-structure`,
				title: t("company_structure"),
			},
			{
				path: null,
				title: department?.name || null,
			},
		]

		return arr
	}, [department, t])

	useEffect(() => {
		!loading && dispatch(setBreadcrumbs(crumbs))
		return () => {
			dispatch(resetBreadcrumbs())
		}
	}, [crumbs, dispatch, loading])

	useEffect(() => {
		dispatch(getPeopleOfDepartment({ departmentId }))
		dispatch(getDepartmentDocuments({ departmentId }))
		dispatch(getDepartment({ departmentId }))
		return () => {
			dispatch(resetDepartment())
		}
	}, [departmentId, dispatch])

	if(loading){
		return <PreloaderDepartment />
	}

	return (
		<>
		<MobileHeader title={department?.name} breadcrumbsTitle={t('company_structure')} />
		<div className={styles.wrapper}>
			<Typography fontVariant="bold" className={styles.title}>
				{i18next?.language === "en" ? `${department?.name} ${t("details_noun")}` : `${t("details_noun")} ${department?.name}` }  
			</Typography>
			<div
				className={classNames(styles.grid, {
					[styles.grid__fit]: !department?.description,
				})}
			>
				<div className={classNames(styles.container, styles.grid__left)}>
					<DocumentsBlock state={documents} />

					<PeopleBlock state={people} />
				</div>
				{department?.description && (
					<div className={classNames(styles.card, styles.grid__right)}>
						<Typography
							className={classNames(
								styles.card__title,
								styles.card__title_header
							)}
						>
							<DepartmentIcon />
							{t("about_department")}
						</Typography>
						<Typography component="p" className={styles.card__about}>
							{department?.description}
						</Typography>
					</div>
				)}
			</div>
		</div>
		</>
	)
}

function Card({ children, title }) {
	const { t } = useTranslation()
	const [toggle, setToggle] = useState(true)
	function handleToggle() {
		setToggle((s) => !s)
	}
	return (
		<div className={classNames(styles.card, styles.card_select)}>
			<div className={styles.card__header} onClick={handleToggle}>
				<Typography className={styles.card__title}>{t(title)}</Typography>
				<button
					type="button"
					className={classNames(styles.arrow, { [styles.arrow_up]: toggle })}
				>
					<ArrowDownIcon />
				</button>
			</div>
			{toggle && (
				<div className={classNames(styles.card__content)}>{children}</div>
			)}
		</div>
	)
}

function DocumentItem({ children, id }) {
	return (
		<Link to={`/knowledge-base/documents/${id}`} className={styles.document}>
			<DocumentIcon />
			<Typography component="span">{children}</Typography>
		</Link>
	)
}

function PeopleItem({ people }) {
	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<Avatar
					name={people?.person_name}
					src={people?.avatar?.small}
					textClassName={classNames(styles.avatar)}
					imgClassName={classNames(styles.avatar)}
					fontVariant="medium"
				/>
				<div>
					<Typography>{people?.person_name}</Typography>
					<Typography className={styles.person_email}>
						{people?.person_email}
					</Typography>
				</div>
			</div>
			<div className={classNames(styles.cell, styles.department)}>
				<Typography>
					{!!people?.departments_path?.length &&
						people?.departments_path[people?.departments_path.length - 1]?.name}
				</Typography>
				<Typography className={styles.position_mobile}>{people?.person_position_name}</Typography>
			</div>
			<div className={classNames(styles.cell, styles.position_desktop)}>
				<Typography>{people?.person_position_name}</Typography>
			</div>
		</div>
	)
}

function DocumentsBlock({ state }) {
	if (!state) {
		return null
	}
	const { data: documents } = state
	return (
		!!documents?.length && (
			<Card title={"documents"}>
				{documents?.map((d) => (
					<DocumentItem id={d?.id} key={d?.id}>
						{d?.name}
					</DocumentItem>
				))}
			</Card>
		)
	)
}
function PeopleBlock({ state }) {
	const [query] = useSearchParams()
	const dispatch = useDispatch()
	const { departmentId } = useParams()
	if (!state) {
		return null
	}
	const { data: people, meta } = state
	return (
		<Card title={"people"}>
			{people?.map((e, i) => (
				<PeopleItem people={e} key={`${e?.id}-people-${i}`} />
			))}
			<LazyPaginator
				meta={meta}
				cb={(page, lastPage) => {
					const newPage = Math.abs(page + 1)
					if (newPage <= +lastPage) {
						query.set("page", newPage)
						const str = query.toString()
						dispatch(
							getPeopleOfDepartment({
								queryString: str,
								departmentId,
								lazy: true,
							})
						)
					}
				}}
			/>
		</Card>
	)
}
